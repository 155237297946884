<template>
  <!-- 合同模板管理 -->
  <div class="contractTemplate">
    <FormSearch :getdata="getdata" :reset="true" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="editAndAddRow">
          添加
        </el-button>
      </div>
      <Table :item-data="itemData" :list-data="listData" :operation-button="operationButton" :loading="loading" />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 弹出的新增跟修改框 -->
    <Dialog ref="dialog" :edit-form-data="editFormData" :form-item="addAndEditFormItem" @getFormData="getFormData">
      <template slot="dynamicInsertAfter">
        <el-form-item label="企业名称">
          <el-select
            v-model="editFormData.cmpCode"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键字搜索"
            :remote-method="remoteMethod"
            @change="getCmpName"
          >
            <el-option v-for="item in cmpNameArr" :key="item.cmpCode" :label="item.cmpName" :value="item.cmpCode" />
          </el-select>
        </el-form-item>
        <el-form-item label="合同内容" prop="compactContent">
          <TinymceEditor
            :introduction-content="editFormData.compactContent"
            @getIntroductionContent="getIntroductionContent"
          />
        </el-form-item>
      </template>
    </Dialog>
  </div>
</template>

<script>
import TinymceEditor from '../../components/TinymceEditor'
import FormSearch from '../../components/FormSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination2'
import Dialog from '../../components/Dialog'
import { timestampToTime } from '../../utils/util'
import { getCmpBaseinfoList, compactServiceListPage, compactServiceSaveAndUpdata, compactServiceDelete } from '../../api/memberManagement'
export default {
  components: { TinymceEditor, FormSearch, Table, Pagination, Dialog },
  data() {
    return {
      cmpNameArr: [{ cmpCode: '-1', cmpName: '全平台' }],
      editFormData: {},
      formItemArr: [
        { type: 'input', label: '合同名称', value: 'compactName' },
        { type: 'select', label: '合同类型', value: 'compactType', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('compactType') }
      ],
      itemData: [
        { label: '企业名称', prop: 'cmpName', width: 180 },
        { label: '合同名称', prop: 'compactName', width: 180 },
        // { label: '合同内容', prop: 'compactContent', width: 180, html: true },
        { label: '合同类型', prop: 'compactType', width: 180, child: this.$store.getters.getDictionaryItem('compactType') },
        { label: '创建人', prop: 'createUser', width: 180 },
        { label: '创建时间', prop: 'createTime', width: 180 },
        { label: '修改人', prop: 'modifyUser', width: 180 },
        { label: '修改时间', prop: 'modifyTime', width: 180 }
      ],
      addAndEditFormItem: [
        { label: '合同名称', type: 'input', value: 'compactName', width: '120px' },
        { label: '方向', type: 'select', value: 'compactTypeset', width: '120px', optionLabel: 'dictName', optionId: 'dictId', child: [{ dictName: '竖向', dictId: 0 }, { dictName: '横向', dictId: 1 }] },
        { label: '合同类型', type: 'select', value: 'compactType', width: '120px', optionLabel: 'dictName', optionId: 'dictId', child: this.$store.getters.getDictionaryItem('compactType') }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow },
        { bType: 'danger', label: '删除', handleEvent: this.deleteRow }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  methods: {
    getIntroductionContent(value) {
      this.editFormData.compactContent = value
    },
    // 获取列表数据
    getdata(type) {
      if (!type) this.formInline.pageNum = 1
      compactServiceListPage(this.formInline, res => {
        this.listData = [...res.data.list]
        this.listData.forEach(item => {
          item.modifyTime = timestampToTime(item.modifyTime)
          item.createTime = timestampToTime(item.createTime)
        })
        this.total = res.data.total
      })
    },
    getCmpName(e) {
      this.cmpNameArr.forEach(item => {
        if (item.cmpCode === e) {
          this.editFormData.cmpName = item.cmpName
        }
      })
    },
    // 联动搜搜货主列表
    remoteMethod(value) {
      if (!value) return
      getCmpBaseinfoList(value, res => {
        this.cmpNameArr = [{ cmpCode: '-1', cmpName: '全平台' }, ...res.data]
      })
    },
    // 新增或修改数据
    editAndAddRow(row) {
      this.$refs.dialog.editFormVisible = true
      this.editFormData = row.compactId ? { ...row } : { cmpName: '', compactType: '', compactTypeset: '', compactName: '', cmpCode: '', compactContent: '' }
      if (row.compactId) this.remoteMethod(row.cmpName)
    },
    // 启用与禁用
    deleteRow(row) {
      this.$confirm('此操作将删除此条信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        compactServiceDelete({ compactId: row.compactId }, res => {
          this.$message.success('成功！')
          this.getdata(true)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 获取到输入框的内容
    getFormData(value) {
      if (!this.editFormData.compactContent) {
        this.$message.error('请填写合同内容！')
        return
      }
      value.compactContent = this.editFormData.compactContent
      value.cmpCode = this.editFormData.cmpCode
      value.cmpName = this.editFormData.cmpName
      compactServiceSaveAndUpdata(value, () => {
        this.getdata(value.id)
        this.$message.success('成功！')
        this.$refs.dialog.editFormVisible = false
      })
    }

  }
}
</script>

<style scoped>
.cmpNameSearch {
  width: 100%;
}

.marginTop {
  padding-top: 18px;
}
</style>
